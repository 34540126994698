<template>
  <div class="mx-auto max-w-7xl my-8">
    <div class="flex flex-col flex-grow">
      <PrivacyStatement />
    </div>
  </div>
</template>

<script>
  import PrivacyStatement from '@/components/PrivacyStatement.vue';

  export default {
    name: 'Privacy',
    components: {
      PrivacyStatement
    }
  };
</script>

<style scoped></style>
